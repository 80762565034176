/* Resume */

#resume {
  .link-to {
    position: relative;
    top: -4.5em;
  }

  .link-container h4 {
    text-decoration: none;
    border-bottom: none;
    display: inline-flex;

    a {
      padding: 0 0.5em;
      margin: 0;
    }
  }
  .link-container h4:first-child a{
    padding-left: 0;
  }

  .education {
    .title {
      text-align: center;
      margin-bottom: 2em;
    }
    .degrees {
      font-size: larger;
      text-align: center;
    }
  }
  .experience {
    .title {
      text-align: center;
      p {
        font-size: 0.9em;
      }
    }
    .job {
      margin: 1em;
      p {
        margin: 0;
      }
    }
  }

  .jobs-container {
    .points {
      li {
        margin: 0;
        padding: 0;
        font-size: 0.9em;
      }
    }
  }

  .courses {
    .title {
      text-align: center;
    }
    
    .course-list {
      list-style-type: none;
      // text-align: center;

    }
    
  }

  .skills {
    margin-top: 2em;
    .title {
      text-align: center;
      p {
        font-size: 0.9em;
      }
    }
  }

  .summary {
    margin-bottom: 0.5em;
    font-size: 0.9em;
  }

  .references {
    margin: 2em 0;
    .title {
      text-align: center;
    }
  }
}

.daterange {
  margin-bottom: 0.2em;
}

.degree-container {
  h4 {
    font-weight: normal;
  }

  .degree {
    margin-bottom: 0.1em;
    text-transform: none;
    letter-spacing: 0.16em;
    font-size: 0.8em;
  }

  .school {
    text-transform: none;
    padding-top: 0.3em;
    margin-bottom: 1em;
  }
}

.course-content {
  display: grid;
  grid-template-columns: 80px 1fr 50px;
}

.courses .course-number {
  font-weight: bold;
}
.courses .course-name {
  display: inline;
  margin-left: 4em;
  font-family: "Raleway", Helvetica, sans-serif;
  font-size: 0.7em;
  font-weight: 400;
  letter-spacing: 0.25em;
  line-height: 2.5;
  text-transform: uppercase;
}
.courses .course-grade {
    text-align: right;
    font-family: "Raleway", Helvetica, sans-serif;
    font-size: 0.9em;
    color: green;
}
